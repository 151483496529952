<template>
    <div>
        <psi-page-title
            :title="page.title"
            :icon="page.icon"
            :breadcrumbs="breadcrumbs"
        ></psi-page-title>
        <!-- Content always starts with a v-card  
        -->
        <v-card class="mt-4" height="300">
            <v-card-text>
                <h4 class="mb-2">Admin Users</h4>
                <v-divider></v-divider>
                <v-autocomplete
                    eager
                    v-model="select"
                    :loading="loading"
                    :items="items"
                    :filter="customFilter"
                    :search-input.sync="search"
                    cache-items
                    item-text="name"
                    item-value="abbrev"
                    class="mx-4"
                    flat
                    hide-details
                    label="What state are you from?"
                    solo-inverted
                ></v-autocomplete>
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
export default {
    name: "admin-users",
    data() {
        return {
            page: {
                title: "Admin Users",
                icon: "mdi-account-cog",
            },
            breadcrumbs: [
                {
                    text: "Applications",
                    disabled: false,
                    to: "#",
                },
                {
                    text: "Admin Users",
                    disabled: true,
                },
            ],
            loading: false,
            items: [],
            search: null,
            select: null,
            states: [
                {
                    name: "Alabama",
                    abbrev: "AL",
                },
                {
                    name: "Alabama",
                    abbrev: "AL",
                },
                {
                    name: "Alaska",
                    abbrev: "AK",
                },
                {
                    name: "American Samoa",
                    abbrev: "AS",
                },
                {
                    name: "Arizona",
                    abbrev: "AZ",
                },
                {
                    name: "Arkansas",
                    abbrev: "AR",
                },
                {
                    name: "California",
                    abbrev: "CA",
                },
                {
                    name: "Colorado",
                    abbrev: "CO",
                },
                {
                    name: "Connecticut",
                    abbrev: "CT",
                },
            ],
        };
    },
    mounted() {},
    watch: {
        search(val) {
            if (val) {
                this.items = this.states.filter(
                    (item) =>
                        item.name.toLowerCase().includes(val.toLowerCase()) ||
                        item.abbrev.toLowerCase().includes(val.toLowerCase())
                );
                console.log(this.items);
            }
        },
    },
    methods: {
        customFilter(item, queryText) {
            const textOne = item.name.toLowerCase();
            const textTwo = item.abbrev.toLowerCase();
            const searchText = queryText.toLowerCase();

            return (
                textOne.indexOf(searchText) > -1 ||
                textTwo.indexOf(searchText) > -1
            );
        },
    },
};
</script>

<style scoped>
</style>