var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("psi-page-title", {
        attrs: {
          title: _vm.page.title,
          icon: _vm.page.icon,
          breadcrumbs: _vm.breadcrumbs
        }
      }),
      _c(
        "v-card",
        { staticClass: "mt-4", attrs: { height: "300" } },
        [
          _c(
            "v-card-text",
            [
              _c("h4", { staticClass: "mb-2" }, [_vm._v("Admin Users")]),
              _c("v-divider"),
              _c("v-autocomplete", {
                staticClass: "mx-4",
                attrs: {
                  eager: "",
                  loading: _vm.loading,
                  items: _vm.items,
                  filter: _vm.customFilter,
                  "search-input": _vm.search,
                  "cache-items": "",
                  "item-text": "name",
                  "item-value": "abbrev",
                  flat: "",
                  "hide-details": "",
                  label: "What state are you from?",
                  "solo-inverted": ""
                },
                on: {
                  "update:searchInput": function($event) {
                    _vm.search = $event
                  },
                  "update:search-input": function($event) {
                    _vm.search = $event
                  }
                },
                model: {
                  value: _vm.select,
                  callback: function($$v) {
                    _vm.select = $$v
                  },
                  expression: "select"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }